export class GruposFakeDb {
    public static grupos = [
        {
            id: '1',
            nombre: 'Inspectores',
            descripcion: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipiscivelit...',
            miembros: ['mmele@e-softing.com.ar', 'nmadera@e-softing.com.ar', 'fmartinez@e-softing.com.ar',
                'nespinosa@e-softing.com.ar', 'ealvarez@e-softing.com.ar', 'jdominguez@e-softing.com.ar']
        },

        {
            id: '2',
            nombre: 'Analistas',
            descripcion: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipiscivelit...',
            miembros: ['nespinosa@e-softing.com.ar', 'ealvarez@e-softing.com.ar', 'jdominguez@e-softing.com.ar', 'jmroldan@e-softing.com.ar', 'hhiga@e-softing.com.ar']
        },

        {
            id: '3',
            nombre: 'Coordinadores',
            descripcion: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipiscivelit...',
            miembros: ['gvalenzuela@e-softing.com.ar', 'hhiga@e-softing.com.ar', 'jmroldan@e-softing.com.ar', 'fmartinez@e-softing.com.ar']
        },

        {
            id: '4',
            nombre: 'Directores',
            descripcion: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipiscivelit...',
            miembros: ['hhiga@e-softing.com.ar', 'jmroldan@e-softing.com.ar', 'ealvarez@e-softing.com.ar']
        },

        {
            id: '5',
            nombre: 'Zapadores',
            descripcion: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipiscivelit...',
            miembros: ['hhiga@e-softing.com.ar']
        }
    ];

}
