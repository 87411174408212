import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UsuarioService } from 'app/servicios/usuario.service';
import { Observable } from 'rxjs';

import { AuthService } from '../servicios/auth.service';

@Injectable({ providedIn: 'root'})
export class AuthGuard implements CanActivate {
    
    constructor(private router: Router, private _authService: AuthService, private _usuarioService: UsuarioService) {        
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const habilitado = this._authService.sesionEsValida();
        const rolUsuario = this._authService.getRole();
        if (!habilitado) {
            this.router.navigate(['/pages/auth/login']);
        }
        else
        {
            if(rolUsuario)
            {
                if (route.data.role && route.data.role.indexOf(rolUsuario) === -1){
                    this.router.navigate(['/apps/bandeja']);
                }
            }
        }
        return habilitado;
    }

}
