import { Injectable } from "@angular/core";
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { Usuario } from "app/modelos/usuario.model";
import { environment } from "environments/environment";
import { catchError, map } from "rxjs/operators";
import { UsuarioDTO } from "app/modelos-dtos/usuarioDTO";

@Injectable({
    providedIn: "root",
})
export class UsuarioService {
    usuarios: Usuario[];
    usuario: Usuario;
    nombreUsuarios: string[];
    searchText = "";
    options: any;
    usuariosPaginacion: any;
    private url = "Usuario";
    private headers = new HttpHeaders({
        "Content-type": "application/json",
    });
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient) {
        this.options = {
            headers: this.headers,
        };
        // Set the defaults
    }

    /**
     * Get usuarios
     *
     * @returns {Promise<any>}
     */

    getUsuariosPrueba(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get("api/usuarios").subscribe((response: any) => {
                this.usuarios = response;

                resolve(this.usuarios);
            }, reject);
        });
    }

    getUsuarios(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(
                    `${environment.backend}${this.url}?paginaNumero=0`,
                    this.options
                )
                .subscribe((response: any) => {
                    this.usuarios = response.datos;

                    resolve(this.usuarios);
                }, reject);
        });
    }

    // Get Usuarios via Observable
    getUsuariosObs(): Observable<Usuario[]> {
        return this._httpClient.get<Usuario[]>("api/usuarios");
    }

    /**
     * Get Usuario
     *
     * @param {id} string
     * @returns {Observable<Usuario>}
     */
    getUsuarioPrueba(id: string): Observable<Usuario> {
        const url = `${"api/usuarios"}/${id}`;
        return this._httpClient.get<Usuario>(url);
    }

    getUsuario(id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(`${environment.backend}${this.url}/${id}`, this.options)
                .subscribe((response: any) => {
                    this.usuario = response.datos;

                    resolve(this.usuario);
                }, reject);
        });
    }

    getUsuariosPaginados(
        numeroPagina: Number,
        tamanioPagina: Number,
        filtrosConcurrentes?: boolean,
        arrayPropiedadesABuscar?: any,
        parametroDeBusqueda?: string
    ): Promise<any> {
        var queryString = "";
        if (arrayPropiedadesABuscar && parametroDeBusqueda) {
            arrayPropiedadesABuscar.forEach((element: string) => {
                queryString =
                    queryString +
                    "&" +
                    element.toLowerCase() +
                    "=" +
                    parametroDeBusqueda.toString();
            });
        }
        let url = `${environment.backend}${this.url}?paginaNumero=${numeroPagina}&paginaTamaño=${tamanioPagina}${queryString}&filtrosConcurrentes=${filtrosConcurrentes}`;
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(url, this.options)
                .subscribe((response: any) => {
                    this.usuariosPaginacion = response;
                    resolve(this.usuariosPaginacion);
                }, reject);
        });
    }

    private manejarError(error: HttpErrorResponse): Observable<any> {
        if (error.status === 401) {
            return of(false);
        }
        return throwError(
            `Status Code: ${error.status}. Error: ${error.message}`
        );
    }

    //Create usuario
    postUsuario(values: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .post(`${environment.backend}${this.url}`, values, this.options)
                .subscribe((response: any) => {
                    const respuesta = response;
                }, reject);
        });
    }

    createUsuario(values: any): Observable<Usuario> {
        return this._httpClient
            .post<any>(
                `${environment.backend}${this.url}/Registrar/`,
                values,
                this.options
            )
            .pipe(
                map((response) => {
                    const respuesta = new Usuario(response["datos"]);
                    this.usuario = respuesta;
                    return this.usuario;
                }),
                catchError((err) => this.manejarError(err))
            );
    }

    deleteUsuario(id: any): Observable<any> {
        return this._httpClient
            .delete<any>(`${environment.backend}${this.url}/${id}`)
            .pipe(
                map((response) => {
                    const respuesta = response;
                    return respuesta;
                }),
                catchError((err) => this.manejarError(err))
            );
    }

    editUsuario(usuario: UsuarioDTO): Observable<string> {
        const datos = JSON.stringify(usuario);
        return this._httpClient
            .put<any>(`${environment.backend}${this.url}`, datos, this.options)
            .pipe(
                map((response) => {
                    const respuesta = response;
                    return respuesta;
                }),
                catchError((err) => this.manejarError(err))
            );
    }

    getUsuarioActual(): Observable<Usuario> {
        return this._httpClient
            .get<any>(
                `${environment.backend}${this.url}/UsuarioActual/`,
                this.options
            )
            .pipe(
                map((response) => {
                    const respuesta = new Usuario(response["datos"]);
                    this.usuario = respuesta;
                    return this.usuario;
                }),
                catchError((err) => this.manejarError(err))
            );
    }

    getUsuarioActualPromise(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(
                    `${environment.backend}${this.url}/UsuarioActual/`,
                    this.options
                )
                .subscribe((response: any) => {
                    this.usuario = response.datos;

                    resolve(this.usuario);
                }, reject);
        });
    }

    getPantallasUsuario(idUsuario: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(
                    `${environment.backend}${this.url}/TraerPantallas/` +
                        idUsuario,
                    this.options
                )
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
}
