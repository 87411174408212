export class TiempoActividad {
    nombreActividad: string;
    tiempo: string;

    /**
     *
     */
    constructor(nombreActividad = '', tiempo = '') {
        this.nombreActividad = nombreActividad;
        this.tiempo = tiempo
    }
}