export class ResultadosFakeDb {
    public static resultados = [
        {
            id: '1',
            nombre: 'Resultados 1',
            areas: ['Obras', 'Higiene', 'Seguridad', 'Resultado 4']      
        },

        {
            id: '2',
            nombre: 'Resultados 2',
            areas: ['Sanidad']   
        },

        {
            id: '3',
            nombre: 'Resultados 3',
            areas: ['Obras', 'Logistica', 'Sanidad', 'Resultado 4', 'Resultado 5', 'Resultado 6', 'Resultado 7', 'Resultado 8']
        },

        {
            id: '4',
            nombre: 'Resultados 4',
            areas: ['Higiene', 'Sanidad', 'Inspecciones']
        },

        {
            id: '5',
            nombre: 'Resultados 5',
            areas: ['Obras', 'Habilitaciones']
        },

        {
            id: '6',
            nombre: 'Resultados 6',
            areas: ['Seguridad', 'Habilitaciones', 'Inspecciones']
        }
    ];

}
