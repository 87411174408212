import { TiempoActividad } from 'app/modelos/tiempoActividad.model';

export class TiempoPromedioFakeDb {
    public static TiempoPorActividad = [
        new TiempoActividad ('Cargar', '03:59:50'),        
        new TiempoActividad ('Aprobar', '01:00:10'),
        new TiempoActividad ('Programar', '00:50:47'),
        new TiempoActividad ('Inspeccionar', '13:01:01'),
        new TiempoActividad ('Calificar', '14:21:27'),
    ];
}