export class UsuariosFakeDb {
    public static usuarios = [
        {
            id: 1,
            nombre: 'Juan Martin',
            apellido: 'Roldan',
            imagenPerfil: 'assets/images/avatars/Trevino.jpg',
            telefono: '12345678',
            email: 'jmroldan@e-softing.com.ar',
            tipoDocumento: 1,
            documento: '1234567890',
            grupos: ['Analista', 'Desarrollador'],
            rol: 'AdministradorSistema'
        },

        {
            id: 2,
            nombre: 'Ezequiel',
            apellido: 'Alvarez',
            imagenPerfil: 'assets/images/avatars/andrew.jpg',
            telefono: '12345678',
            email: 'ealvarez@e-softing.com.ar',
            tipoDocumento: 0,
            documento: '1234567890',
            grupos: ['Analista', 'Desarrollador'],
            rol: 'AdministradorSistema'
        },

        {
            id: 3,
            nombre: 'Hector',
            apellido: 'Higa',
            imagenPerfil: 'assets/images/avatars/Harper.jpg',
            telefono: '12345678',
            email: 'hhiga@e-softing.com.ar',
            tipoDocumento: 0,
            documento: '1234567890',
            grupos: ['Analista', 'Desarrollador'],
            rol: 'AdministradorSistema'
        },

        {
            id: 4,
            nombre: 'Nicolas',
            apellido: 'Espinosa',
            imagenPerfil: 'assets/images/avatars/james.jpg',
            telefono: '12345678',
            email: 'nespinosa@e-softing.com.ar',
            tipoDocumento: 1,
            documento: '1234567890',
            grupos: ['Analista', 'Desarrollador'],
            rol: 'AdministradorSistema'
        },

        {
            id: 5,
            nombre: 'Jorge',
            apellido: 'Dominguez',
            imagenPerfil: 'assets/images/avatars/Tyson.jpg',
            telefono: '12345678',
            email: 'jdominguez@e-softing.com.ar',
            tipoDocumento: 1,
            documento: '1234567890',
            grupos: ['Analista', 'Desarrollador'],
            rol: 'AdministradorSistema'
        },

        {
            id: 6,
            nombre: 'Gabriel',
            apellido: 'Valenzuela',
            imagenPerfil: 'assets/images/avatars/Copeland.jpg',
            telefono: '12345678',
            email: 'gvalenzuela@e-softing.com.ar',
            tipoDocumento: 0,
            documento: '1234567890',
            grupos: ['Analista', 'Desarrollador'],
            rol: 'AdministradorSistema'
        },

        {
            id: 7,
            nombre: 'Martin',
            apellido: 'Mele',
            imagenPerfil: 'assets/images/avatars/Tillman.jpg',
            telefono: '12345678',
            email: 'mmele@e-softing.com.ar',
            tipoDocumento: 1,
            documento: '1234567890',
            grupos: ['Analista', 'Desarrollador'],
            rol: 'AdministradorSistema'
        },

        {
            id: 8,
            nombre: 'Fernando',
            apellido: 'Iglesias',
            imagenPerfil: 'assets/images/avatars/Arnold.jpg',
            telefono: '12345678',
            email: 'figlesias@e-softing.com.ar',
            tipoDocumento: 0,
            documento: '1234567890',
            grupos: ['Analista', 'Desarrollador'],
            rol: 'AdministradorSistema'
        },

        {
            id: 9,
            nombre: 'Pablo',
            apellido: 'Silvestre',
            imagenPerfil: 'assets/images/avatars/vincent.jpg',
            telefono: '12345678',
            email: 'psilvestre@e-softing.com.ar',
            tipoDocumento: 0,
            documento: '1234567890',
            grupos: ['Analista', 'Desarrollador'],
            rol: 'AdministradorSistema'
        },

        {
            id: 10,
            nombre: 'Nicolas',
            apellido: 'Madera',
            imagenPerfil: 'assets/images/avatars/Shepard.jpg',
            telefono: '12345678',
            email: 'nmadera@e-softing.com.ar',
            tipoDocumento: 1,
            documento: '1234567890',
            grupos: ['Analista', 'Desarrollador'],
            rol: 'AdministradorSistema'
        },
        {
            id: 11,
            nombre: 'Prueba',
            apellido: 'Prueba',
            imagenPerfil: 'assets/images/avatars/Shepard.jpg',
            telefono: '12345678',
            email: 'asdasd@e-softing.com.ar',
            tipoDocumento: 1,
            documento: '1234567890',
            grupos: ['Analista'],
            rol: 'AdministradorSistema'
        },
        {
            id: 12,
            nombre: 'Prueba 2',
            apellido: 'Prueba 2',
            imagenPerfil: 'assets/images/avatars/Shepard.jpg',
            telefono: '12345678',
            email: 'asdasd2@e-softing.com.ar',
            tipoDocumento: 1,
            documento: '1234567890',
            grupos: ['Analista'],
            rol: 'AdministradorSistema'
        }
    ];

}
