import { FuseNavigation } from '@fuse/types';


export const navigation: FuseNavigation[] = [
    {        
        id      : 'menu',
        title   : '',
        type    : 'group',
        icon    : '',
        children: [
            {
                id      : 'bandeja',
                title   : 'Bandeja',
                type    : 'item',
                icon    : 'all_inbox',
                url     : '/apps/bandeja',
                badge    : {
                    title: '25',
                    bg   : '#C02942',
                    fg   : '#FFFFFF'
                }
            },
            {
                id      : 'usuarios',
                title   : 'Usuarios',
                type    : 'item',
                icon    : 'supervised_user_circle',
                url     : '/apps/usuarios'
            },
            {
                id      : 'grupos',
                title   : 'Grupos',
                type    : 'item',
                icon    : 'group_work',
                url     : '/apps/grupos'
            },
            {
                id      : 'areas',
                title   : 'Areas',
                type    : 'item',
                icon    : 'flag',
                url     : '/apps/areas'
            },
            {
                id      : 'motivos',
                title   : 'Motivos',
                type    : 'item',
                icon    : 'label',
                url     : '/apps/motivos'
            },
            {
                id      : 'estadisticas',
                title   : 'Estadísticas',
                type    : 'item',
                icon    : 'assessment',
                url     : '/apps/estadisticas'
            },
            {
                id      : 'checklists',
                title   : 'Checklists',
                type    : 'item',
                icon    : 'list_alt',
                url     : '/apps/checklist'
            },
            {
                id      : 'resultados',
                title   : 'Resultados',
                type    : 'item',
                icon    : 'toc',
                url     : '/apps/resultados'
            },
            {
                id      : 'procesos',
                title   : 'Procesos',
                type    : 'item',
                icon    : 'settings',
                url     : '/apps/procesos',                
                badge    : {
                    title: '3',
                    bg   : '#79BD9A',
                    fg   : '#FFFFFF'
                }
            },
            {
                id      : 'busqueda',
                title   : 'Busqueda',
                type    : 'item',
                icon    : 'search',
                url     : '/apps/busqueda'
            },
            {
                id      : 'reglas',
                title   : 'Reglas',
                type    : 'item',
                icon    : 'playlist_add_check',
                url     : '/apps/reglas'
            }
        ]
    },
    {
        id      : 'help',
        title   : '',
        type    : 'group',
        icon    : '',
        classes : 'custom-menu-group',
        children: [
            {
                id      : 'cerrarSesion',
                title   : 'Cerrar sesión',
                type    : 'item',
                icon    : 'exit_to_app',
                url     : '/apps/logout',
                classes   : 'custom-menu-item' 
            }
        ]   
    }
];
