export class MotivosFakeDb
{
    public static motivos = [
        {
            id        : '1',
            nombre    : 'Nombre del Motivo',
            areas     :  ['Obras', 'Higiene', 'Seguridad', 'Habilitaciones', 'Logistica', 'Inspecciones', 'Sanidad']
        },

        {
            id        : '2',
            nombre    : 'Nombre del Motivo',
            areas: ['Obras', 'Higiene', 'Seguridad', 'Habilitaciones', 'Logistica']
        },

        {
            id        : '3',
            nombre    : 'Otro nombre de Motivo bien largo para probar',
            areas: ['Obras', 'Higiene', 'Seguridad', 'Habilitaciones', 'Logistica', 'Inspecciones', 'Sanidad', 'Inspecciones 2', 'Logistica 2']
        },
        {
            id        : '4',
            nombre    : 'Nombre del Motivo',
            areas: ['Obras', 'Higiene', 'Seguridad', 'Habilitaciones']
        },

        {
            id        : '5',
            nombre    : 'Otro nombre de Motivo bien largo para probar',
            areas: ['Obras', 'Higiene', 'Seguridad', 'Habilitaciones', 'Logistica', 'Inspecciones', 'Sanidad', 'Inspecciones 2', 'Logistica 2']
        },

        {
            id        : '6',
            nombre    : 'Nombre del Motivo',
            areas: ['Obras', 'Higiene', 'Seguridad', 'Habilitaciones', 'Logistica', 'Inspecciones', 'Sanidad']
        },
    ];

}
