export class SesionUsuario {
    token: string;
    fechaDeExpiracion: Date;

    constructor(token: string, fechaDeExpiracion: Date){
        this.token = token;
        this.fechaDeExpiracion = fechaDeExpiracion;
    }

    public tokenEsValido(): boolean {
        return this.fechaDeExpiracion.getTime() > Date.now();
    }
}
