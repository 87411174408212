import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from '../servicios/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    
    constructor(private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const sesionActual = this.authService.getSesionActual;

        if (sesionActual){
            const peticionConCredenciales = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${sesionActual.token}`)
            });

            return next.handle(peticionConCredenciales);
        }else{
            return next.handle(req);
        }
    }

}
