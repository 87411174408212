
import { TipoRespuesta } from 'app/modelos/tipos/TipoRespuesta';
import { truncateSync } from 'fs';

export class ChecklistsFakeDb
{
    public static checklists = [
   

        {
            id: '1',
            Nombre: 'Checklist de Obras 1',
            FechaEdicion: '01/09/2020',
            Descripcion: 'Descripcion de Chekclist 1',
            Areas: [
                {id: 1, nombre    : 'area uno'},
                {id: 2, nombre    : 'area dos'},
                {id: 3, nombre    : 'area tres'}
            ],
            Motivos: [
                {id: 1, nombre    : 'motivo uno'},
                {id: 2, nombre    : 'motivo dos'},
                {id: 3, nombre    : 'motivo tres'}
            ],
            Categorias: [],
            Publicado: false,
            Version: 1,
            ChecklistPadre:  null
        },
        {
            id: '3',
            Nombre: 'Checklist de Obras 4',
            FechaEdicion: '02/09/2020',
            Descripcion: 'Descripcion de Chekclist 1',
            Areas: [
                {id: 1, nombre    : 'area uno'},
                {id: 2, nombre    : 'area dos'},
                {id: 3, nombre    : 'area tres'}
            ],
            Motivos: [
                {id: 1, nombre    : 'motivo uno'},
                {id: 2, nombre    : 'motivo dos'},
                {id: 3, nombre    : 'motivo tres'}
            ],
            Categorias: [
                {
                    nombre:"Categoria test 1",
                    orden:1,
                    esInicial:true,
                    areas: [],
                    motivos: [],
                    preguntas:[
                                    {
                                        nombre:"Informe de Inspeccion",
                                        tipo:"texto",
                                        orden:1,
                                        textoAyuda:"ayuda"
                                    },
                                    {
                                        nombre:"Fecha y Hora de Inspeccion",
                                        tipo:"fecha",
                                        orden:2,
                                        textoAyuda:"ayuda"
                                    },
                                    {
                                        nombre:"Cantidad generica",
                                        tipo:"entero",
                                        orden:3,
                                        textoAyuda:"ayuda"
                                    },
                                    {
                                        nombre:"Si o no?",
                                        tipo:"booleano",
                                        orden:3,
                                        textoAyuda:"ayuda"
                                    }
                                ],
                    condicionesShow:[]
                },
                {
                    nombre:"Categoria test 2",
                    orden:2,
                    esInicial:false,
                    areas: [],
                    motivos: [],
                    preguntas:[
                                    {
                                        nombre:"Pregunta 2",
                                        tipo:"fecha",
                                        orden:1,
                                        textoAyuda:"ayuda"
                                    }
                                ],
                    condicionesShow:[]
                }
             ],
            Publicado: false,
            Version: 2,
            ChecklistPadre: {
                id: '1',
                Nombre: 'Checklist de Obras 1',
                FechaEdicion: '01/09/2020',
                Descripcion: 'Descripcion de Chekclist 1',
                Areas: [
                    {id: 1, nombre    : 'area uno'},
                    {id: 2, nombre    : 'area dos'},
                    {id: 3, nombre    : 'area tres'}
                ],
                Motivos: [
                    {id: 1, nombre    : 'motivo uno'},
                    {id: 2, nombre    : 'motivo dos'},
                    {id: 3, nombre    : 'motivo tres'}
                ],
                Categorias: [],
                Publicado: false,
                Version: 1,
                ChecklistPadre:  null
            }
        },
        {
            id: '5',
            Nombre: 'Checklist de Obras 5',
            FechaEdicion: '02/09/2020',
            Descripcion: 'Descripcion de Chekclist 1',
            Areas: [
                {id: 1, nombre    : 'area uno'},
                {id: 2, nombre    : 'area dos'},
                {id: 3, nombre    : 'area tres'}
            ],
            Motivos: [
                {id: 1, nombre    : 'motivo uno'},
                {id: 2, nombre    : 'motivo dos'},
                {id: 3, nombre    : 'motivo tres'}
            ],
            Categorias: [],
            Publicado: false,
            Version: 3,
            ChecklistPadre: {
                id: '1',
                Nombre: 'Checklist de Obras 1',
                FechaEdicion: '01/09/2020',
                Descripcion: 'Descripcion de Chekclist 1',
                Areas: [
                    {id: 1, nombre    : 'area uno'},
                    {id: 2, nombre    : 'area dos'},
                    {id: 3, nombre    : 'area tres'}
                ],
                Motivos: [
                    {id: 1, nombre    : 'motivo uno'},
                    {id: 2, nombre    : 'motivo dos'},
                    {id: 3, nombre    : 'motivo tres'}
                ],
                Categorias: [],
                Publicado: false,
                Version: 1,
                ChecklistPadre:  null
            }
        },
        {
            id: '16',
            Nombre: 'Checklist de Obras 16',
            FechaEdicion: '15/09/2020',
            Descripcion: 'Descripcion de Chekclist 1',
            Areas: [
                {id: 1, nombre    : 'area uno'},
                {id: 2, nombre    : 'area dos'},
                {id: 3, nombre    : 'area tres'}
            ],
            Motivos: [
                {id: 1, nombre    : 'motivo uno'},
                {id: 2, nombre    : 'motivo dos'},
                {id: 3, nombre    : 'motivo tres'}
            ],
            Categorias: [],
            Publicado: false,
            Version: 4,
            ChecklistPadre: {
                id: '1',
                Nombre: 'Checklist de Obras 1',
                FechaEdicion: '01/09/2020',
                Descripcion: 'Descripcion de Chekclist 1',
                Areas: [
                    {id: 1, nombre    : 'area uno'},
                    {id: 2, nombre    : 'area dos'},
                    {id: 3, nombre    : 'area tres'}
                ],
                Motivos: [
                    {id: 1, nombre    : 'motivo uno'},
                    {id: 2, nombre    : 'motivo dos'},
                    {id: 3, nombre    : 'motivo tres'}
                ],
                Categorias: [],
                Publicado: false,
                Version: 1,
                ChecklistPadre:  null
            }
        },
        {
            id: '17',
            Nombre: 'Checklist de Obras 17',
            FechaEdicion: '17/09/2020',
            Descripcion: 'Descripcion de Chekclist 1',
            Areas: [
                {id: 1, nombre    : 'auno'},
                {id: 2, nombre    : 'ados'},
                {id: 3, nombre    : 'atres'},
                {id: 4, nombre    : 'acuat'},
                {id: 4, nombre    : 'acinc'},
                {id: 6, nombre    : 'aseis'}
            ],
            Motivos: [
                {id: 1, nombre    : 'muno'},
                {id: 2, nombre    : 'mdos'},
                {id: 3, nombre    : 'mtres'}
            ],
            Categorias: [
                {
                    nombre:"Categoria test 1",
                    orden:1,
                    esInicial:true,
                    areas: [],
                    motivos: [],
                    preguntas:[
                                    {
                                        nombre:"Pregunta 1",
                                        tipo:"texto",
                                        orden:1,
                                        textoAyuda:"ayuda"
                                    }
                                ],
                    condicionesShow:[]
                }
             ],
            Publicado: true,
            Version: 5,
            ChecklistPadre: {
                id: '1',
                Nombre: 'Checklist de Obras 1',
                FechaEdicion: '01/09/2020',
                Descripcion: 'Descripcion de Chekclist 1',
                Areas: [
                    {id: 1, nombre    : 'area uno'},
                    {id: 2, nombre    : 'area dos'},
                    {id: 3, nombre    : 'area tres'}
                ],
                Motivos: [
                    {id: 1, nombre    : 'motivo uno'},
                    {id: 2, nombre    : 'motivo dos'},
                    {id: 3, nombre    : 'motivo tres'}
                ],
                Categorias: [],
                Publicado: false,
                Version: 1,
                ChecklistPadre:  null
            }
        },
        {
            id: '18',
            Nombre: 'Checklist de Obras 18',
            FechaEdicion: '18/09/2020',
            Descripcion: 'Descripcion de Chekclist 1',
            Areas: [
                {id: 1, nombre    : 'auno'},
                {id: 2, nombre    : 'ados'},
                {id: 3, nombre    : 'atres'},
                {id: 4, nombre    : 'acuat'},
                {id: 4, nombre    : 'acinc'},
                {id: 6, nombre    : 'aseis'}
            ],
            Motivos: [
                {id: 1, nombre    : 'muno'},
                {id: 2, nombre    : 'mdos'},
                {id: 3, nombre    : 'mtres'}
            ],
            Categorias: [],
            Publicado: false,
            Version: 6,
            ChecklistPadre: {
                id: '1',
                Nombre: 'Checklist de Obras 1',
                FechaEdicion: '01/09/2020',
                Descripcion: 'Descripcion de Chekclist 1',
                Areas: [
                    {id: 1, nombre    : 'area uno'},
                    {id: 2, nombre    : 'area dos'},
                    {id: 3, nombre    : 'area tres'}
                ],
                Motivos: [
                    {id: 1, nombre    : 'motivo uno'},
                    {id: 2, nombre    : 'motivo dos'},
                    {id: 3, nombre    : 'motivo tres'}
                ],
                Categorias: [],
                Publicado: false,
                Version: 1,
                ChecklistPadre:  null
            }
        },
        {
            id: '19',
            Nombre: 'Checklist de Obras 19',
            FechaEdicion: '19/09/2020',
            Descripcion: 'Descripcion de Chekclist 1',
            Areas: [
                {id: 1, nombre    : 'auno'},
                {id: 2, nombre    : 'ados'},
                {id: 3, nombre    : 'atres'},
                {id: 4, nombre    : 'acuat'},
                {id: 4, nombre    : 'acinc'},
                {id: 6, nombre    : 'aseis'}
            ],
            Motivos: [
                {id: 1, nombre    : 'muno'},
                {id: 2, nombre    : 'mdos'},
                {id: 3, nombre    : 'mtres'}
            ],
            Categorias: [],
            Publicado: false,
            Version: 7,
            ChecklistPadre: {
                id: '1',
                Nombre: 'Checklist de Obras 1',
                FechaEdicion: '01/09/2020',
                Descripcion: 'Descripcion de Chekclist 1',
                Areas: [
                    {id: 1, nombre    : 'area uno'},
                    {id: 2, nombre    : 'area dos'},
                    {id: 3, nombre    : 'area tres'}
                ],
                Motivos: [
                    {id: 1, nombre    : 'motivo uno'},
                    {id: 2, nombre    : 'motivo dos'},
                    {id: 3, nombre    : 'motivo tres'}
                ],
                Categorias: [],
                Publicado: false,
                Version: 1,
                ChecklistPadre:  null
            }
        },
        {
            id: '20',
            Nombre: 'Checklist de Obras 20',
            FechaEdicion: '20/09/2020',
            Descripcion: 'Descripcion de Chekclist 1',
            Areas: [
                {id: 1, nombre    : 'auno'},
                {id: 2, nombre    : 'ados'},
                {id: 3, nombre    : 'atres'},
                {id: 4, nombre    : 'acuat'},
                {id: 4, nombre    : 'acinc'},
                {id: 6, nombre    : 'aseis'}
            ],
            Motivos: [
                {id: 1, nombre    : 'muno'},
                {id: 2, nombre    : 'mdos'},
                {id: 3, nombre    : 'mtres'}
            ],
            Categorias: [],
            Publicado: false,
            Version: 8,
            ChecklistPadre: {
                id: '1',
                Nombre: 'Checklist de Obras 1',
                FechaEdicion: '01/09/2020',
                Descripcion: 'Descripcion de Chekclist 1',
                Areas: [
                    {id: 1, nombre    : 'area uno'},
                    {id: 2, nombre    : 'area dos'},
                    {id: 3, nombre    : 'area tres'}
                ],
                Motivos: [
                    {id: 1, nombre    : 'motivo uno'},
                    {id: 2, nombre    : 'motivo dos'},
                    {id: 3, nombre    : 'motivo tres'}
                ],
                Categorias: [],
                Publicado: false,
                Version: 1,
                ChecklistPadre:  null
            }
        },
        {
            Nombre: 'Checklist de COVID',
            Areas: [ {id: 1, nombre    : 'area uno'}, ],
            Motivos: [ {id: 1, nombre    : 'motivo uno'}, ],
            Categorias: [
                {
                    nombre: 'Inicial Salud',
                    orden: 1,
                    esInicial: true,
                    checklistId: 2,                    
                    condicionesDeShow: [],
                    areas: [],
                    motivos: [],
                    preguntas: [
                        {
                            nombre:"Texto",
                            tipo:"texto",
                            orden:1,
                            textoAyuda:"ayuda"
                        },
                        {
                            nombre:"Fecha",
                            tipo:"fecha",
                            orden:2,
                            textoAyuda:"ayuda"
                        },
                        {
                            nombre:"Entero",
                            tipo:"entero",
                            orden:3,
                            textoAyuda:"ayuda"
                        },
                        {
                            nombre:"Booleano",
                            tipo:"booleano",
                            orden:3,
                            textoAyuda:"ayuda"
                        },
                        {
                            nombre:"Decimal",
                            tipo:"decimal",
                            orden:3,
                            textoAyuda:"ayuda"
                        },
                        {
                            nombre:"Establecimiento Abierto",
                            tipo:"booleano",
                            orden:3,
                            textoAyuda:"ayuda"
                        }
                    ],
                    id: 2,
                    fechaEdicion: '2021-04-06T01:12:42.593456'
                },
                {
                    nombre: 'Establecimiento abierto',
                    orden: 1,
                    esInicial: false,
                    checklistId: 2,                    
                    condicionesDeShow: [],
                    areas: [],
                    motivos: [],
                    preguntas: [
                        {
                            nombre:"Descripcion",
                            tipo:"descripcion",
                            orden:1,
                            textoAyuda:"ayuda"
                        },
                        {
                            nombre:"Hora",
                            tipo:"hora",
                            orden:2,
                            textoAyuda:"ayuda"
                        },
                        {
                            nombre:"Fechahora",
                            tipo:"fechahora",
                            orden:3,
                            textoAyuda:"ayuda"
                        },
                        {
                            nombre:"Decimal",
                            tipo:"decimal",
                            orden:3,
                            textoAyuda:"ayuda"
                        },
                        {
                            nombre:"Decimal",
                            tipo:"decimal",
                            orden:3,
                            textoAyuda:"ayuda"
                        }
                    ],
                    id: 3,
                    fechaEdicion: '2021-04-06T01:12:42.604289'
                },
                {
                    nombre: 'Selectores',
                    orden: 1,
                    esInicial: false,
                    checklistId: 2,                    
                    condicionesDeShow: [],
                    areas: [],
                    motivos: [],
                    preguntas: [
                        {
                            nombre:"Selector1",
                            tipo:"selector",
                            orden:1,
                            textoAyuda:"ayuda",
                            opciones: [
                                {
                                    nombre: "opcion 1",
                                    id: 1
                                },
                                {
                                    nombre: "opcion 2",
                                    id: 2
                                },
                                {
                                    nombre: "opcion 3",
                                    id: 3
                                },
                                {
                                    nombre: "opcion 4",
                                    id: 4
                                }
                            ]
                        },
                        {
                            nombre:"Selector2",
                            tipo:"selectorMultiple",
                            orden:1,
                            textoAyuda:"ayuda",
                            opciones: [
                                {
                                    nombre: "uno",
                                    id: 1
                                },
                                {
                                    nombre: "dos",
                                    id: 2
                                },
                                {
                                    nombre: "tres",
                                    id: 3
                                },
                                {
                                    nombre: "cuatro",
                                    id: 4
                                },
                                {
                                    nombre: "cinco",
                                    id: 5
                                }
                            ]
                        }
                    ],
                    id: 3,
                    fechaEdicion: '2021-04-06T01:12:42.604289'
                }
            ],
            Publicado: true,
            Version: 1,
            ChecklistPadre: null,
            ChecklistPadreId: null,
            id: '2',
            FechaEdicion: '2021-04-06T01:12:42.509498'
        },
        {
            id: '6',
            Nombre: 'Checklist de Obras 6',
            FechaEdicion: '04/09/2020',
            Descripcion: 'Descripcion de Chekclist 2',
            Areas: [ {id: 1, nombre    : 'area uno'}, ],
            Motivos: [ {id: 1, nombre    : 'motivo uno'}, ],
            Categorias: [],
            Publicado: false,
            Version: 2,
            ChecklistPadre: {
                id: '2',
                Nombre: 'Checklist de Obras 2',
                FechaEdicion: '03/09/2020',
                Descripcion: 'Descripcion de Chekclist 2',
                Areas: [ {id: 1, nombre    : 'area uno'}, ],
                Motivos: [ {id: 1, nombre    : 'motivo uno'}, ],
                Categorias: [],
                Publicado: false,
                Version: 1,
                ChecklistPadre: {}
            }
        },
        {
            id: '7',
            Nombre: 'Checklist de Obras 7',
            FechaEdicion: '04/09/2020',
            Descripcion: 'Descripcion de Chekclist 2',
            Areas: [ {id: 1, nombre    : 'area uno'}, ],
            Motivos: [ {id: 1, nombre    : 'motivo uno'}, ],
            Categorias: [],
            Publicado: true,
            Version: 3,
            ChecklistPadre: {
                id: '2',
                Nombre: 'Checklist de Obras 2',
                FechaEdicion: '03/09/2020',
                Descripcion: 'Descripcion de Chekclist 2',
                Areas: [ {id: 1, nombre    : 'area uno'}, ],
                Motivos: [ {id: 1, nombre    : 'motivo uno'}, ],
                Categorias: [],
                Publicado: false,
                Version: 1,
                ChecklistPadre: {}
            }
        },
                
        {
            id: '3',
            Nombre: 'Checklist de Obras 3',
            FechaEdicion: '05/09/2020',
            Descripcion: 'Descripcion de Chekclist 3',
            Areas: [ {id: 2, nombre    : 'area dos'}, ],
            Motivos: [ {id: 2, nombre    : 'motivo dos'}, ],
            Categorias: [],
            Publicado: false,
            Version: 1,
            ChecklistPadre: {}
        },
        {
            id: '8',
            Nombre: 'Checklist de Obras 8',
            FechaEdicion: '06/09/2020',
            Descripcion: 'Descripcion de Chekclist 3',
            Areas: [ {id: 2, nombre    : 'area dos'}, ],
            Motivos: [ {id: 2, nombre    : 'motivo dos'}, ],
            Categorias: [],
            Publicado: true,
            Version: 2,
            ChecklistPadre: {
                id: '3',
                Nombre: 'Checklist de Obras 3',
                FechaEdicion: '05/09/2020',
                Descripcion: 'Descripcion de Chekclist 3',
                Areas: [ {id: 2, nombre    : 'area dos'}, ],
                Motivos: [ {id: 2, nombre    : 'motivo dos'}, ],
                Categorias: [],
                Publicado: false,
                Version: 1,
                ChecklistPadre: {}
            }
        },
        {
            id: '9',
            Nombre: 'Checklist de prueba 9',
            FechaEdicion: '07/09/2020',
            Descripcion: 'Descripcion de Chekclist 9',
            Areas: [ {id: 2, nombre    : 'area dos'}, ],
            Motivos: [ {id: 2, nombre    : 'motivo dos'}, ],
            Categorias: [],
            Publicado: false,
            Version: 1,
            ChecklistPadre: {}
        },
        {
            id: '10',
            Nombre: 'Checklist de prueba 10',
            FechaEdicion: '08/09/2020',
            Descripcion: 'Descripcion de Chekclist 10',
            Areas: [ {id: 2, nombre    : 'area dos'}, ],
            Motivos: [ {id: 2, nombre    : 'motivo dos'}, ],
            Categorias: [],
            Publicado: false,
            Version: 2,
            ChecklistPadre: {
                id: '9',
                Nombre: 'Checklist de prueba 9',
                FechaEdicion: '07/09/2020',
                Descripcion: 'Descripcion de Chekclist ',
                Areas: [ {id: 2, nombre    : 'area dos'}, ],
                Motivos: [ {id: 2, nombre    : 'motivo dos'}, ],
                Categorias: [],
                Publicado: false,
                Version: 1,
                ChecklistPadre: {}
            }
        },
        {
            id: '11',
            Nombre: 'Checklist de Obras 11',
            FechaEdicion: '08/09/2020',
            Descripcion: 'Descripcion de Chekclist ',
            Areas: [ {id: 2, nombre    : 'area dos'}, ],
            Motivos: [ {id: 2, nombre    : 'motivo dos'}, ],
            Categorias: [],
            Publicado: true,
            Version: 3,
            ChecklistPadre: {
                id: '9',
                Nombre: 'Checklist de prueba 9',
                FechaEdicion: '07/09/2020',
                Descripcion: 'Descripcion de Chekclist ',
                Areas: [ {id: 2, nombre    : 'area dos'}, ],
                Motivos: [ {id: 2, nombre    : 'motivo dos'}, ],
                Categorias: [],
                Publicado: false,
                Version: 1,
                ChecklistPadre: {}
            }
        },
        {
            id: '12',
            Nombre: 'Checklist de Obras 12',
            FechaEdicion: '08/09/2020',
            Descripcion: 'Descripcion de Chekclist 12',
            Areas: [ {id: 2, nombre    : 'area dos'}, ],
            Motivos: [ {id: 2, nombre    : 'motivo dos'}, ],
            Categorias: [],
            Publicado: false,
            Version: 4,
            ChecklistPadre: {
                id: '9',
                Nombre: 'Checklist de prueba 9',
                FechaEdicion: '07/09/2020',
                Descripcion: 'Descripcion de Chekclist ',
                Areas: [ {id: 2, nombre    : 'area dos'}, ],
                Motivos: [ {id: 2, nombre    : 'motivo dos'}, ],
                Categorias: [],
                Publicado: false,
                Version: 1,
                ChecklistPadre: {}
            }
        },
        {
            id: '13',
            Nombre: 'Checklist 13',
            FechaEdicion: '10/09/2020',
            Descripcion: 'Descripcion de Chekclist ',
            Areas: [ {id: 2, nombre    : 'area dos'}, ],
            Motivos: [ {id: 2, nombre    : 'motivo dos'}, ],
            Categorias: [],
            Publicado: false,
            Version: 1,
            ChecklistPadre: {}
        },
        {
            id: '14',
            Nombre: 'Checklist 14',
            FechaEdicion: '11/09/2020',
            Descripcion: 'Descripcion de Chekclist ',
            Areas: [ {id: 2, nombre    : 'area dos'}, ],
            Motivos: [ {id: 2, nombre    : 'motivo dos'}, ],
            Categorias: [],
            Publicado: true,
            Version: 2,
            ChecklistPadre: {
                id: '13',
                Nombre: 'Checklist 13',
                FechaEdicion: '10/09/2020',
                Descripcion: 'Descripcion de Chekclist ',
                Areas: [ {id: 2, nombre    : 'area dos'}, ],
                Motivos: [ {id: 2, nombre    : 'motivo dos'}, ],
                Categorias: [],
                Publicado: false,
                Version: 1,
                ChecklistPadre: {}
            }
        },
        {
            id: '15',
            Nombre: 'Checklis 15',
            FechaEdicion: '11/09/2020',
            Descripcion: 'Descripcion de Chekclist ',
            Areas: [ {id: 2, nombre    : 'area dos'}, ],
            Motivos: [ {id: 2, nombre    : 'motivo dos'}, ],
            Categorias: [],
            Publicado: false,
            Version: 3,
            ChecklistPadre: {
                id: '13',
                Nombre: 'Checklist 13',
                FechaEdicion: '10/09/2020',
                Descripcion: 'Descripcion de Chekclist ',
                Areas: [ {id: 2, nombre    : 'area dos'}, ],
                Motivos: [ {id: 2, nombre    : 'motivo dos'}, ],
                Categorias: [],
                Publicado: false,
                Version: 1,
                ChecklistPadre: {}
            }
        },
        
        
    ];

}
