export class RankinInspectoresFakeDb {
    public static usuarios = [{
        nombre: 'test',
        email:'test@mail.com',
        imagenPerfil:'assets/images/avatars/Trevino.jpg',
        ticketsFinalizados: 999
      },
      {
        nombre: 'test',
        email:'test@mail.com',
        imagenPerfil:'assets/images/avatars/Trevino.jpg',
        ticketsFinalizados: 999
      },
      {
        nombre: 'test2',
        email:'test2@mail.com',
        imagenPerfil:'assets/images/avatars/andrew.jpg',
        ticketsFinalizados: 985
      },
      {
        nombre: 'test3',
        email:'test3@mail.com',
        imagenPerfil:'assets/images/avatars/Harper.jpg',
        ticketsFinalizados: 9
      },];
}