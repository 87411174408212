export class ReglasFakeDb {
    public static reglas = [
        {
            id: 1,
            procesoOrigen: { id: 1, nombre: 'Proceso de inspeccion automatizado' },
            actividadOrigen: { id: 1, nombre: 'Esperar inspector' },
            area: { id: 1, nombre: 'Obras' },
            motivo: {
                id: 1,
                nombre: 'Inspeccion de rutina'
            },
            resultados: [{
                id: 1,
                nombre: 'Con irregularidades'
            },
            {
                id: 2,
                nombre: 'Sin irregularidades'
            }
            ],
            accion: 0,
            usaDatosDeOrigen: 0,
            criticidad: 2,
        },

        {
            id: 2,
            procesoOrigen: { id: 2, nombre: 'Registrar documentacion' },
            actividadOrigen: { id: 5, nombre: 'Esperar documentacion' },
            area: {
                id: 2, nombre: 'Salud'
            },
            motivo: {
                id: 2, nombre: 'Reinspeccion'
            },
            resultados: [{
                id: 1,
                nombre: 'Con irregularidades'
            },
            {
                id: 2,
                nombre: 'Sin irregularidades'
            }
            ],
            accion: 1,
            grupos: [{
                id: 1,
                nombre: 'Cargadores'
            },
            {
                id: 2,
                nombre: 'Inspectores'
            }
            ]
        },

        {
            id: 3,
            procesoOrigen: { id: 2, nombre: 'Registrar documentacion' },
            actividadOrigen: { id: 6, nombre: 'Anular' },
            area: { id: 1, nombre: 'Obras' },
            motivo: {
                id: 1,
                nombre: 'Inspeccion de rutina'
            },
            resultados: [{
                id: 1,
                nombre: 'Con irregularidades'
            },
            {
                id: 2,
                nombre: 'Sin irregularidades'
            }
            ],
            accion: 2,
            usuarios: [{
                id: 1,
                nombre: 'Carlos',
                apellido: 'Desarrollo'
            },
            {
                id: 2,
                nombre: 'Usuario 1',
                apellido: 'Cargador'
            }
            ]
        },
    ];

}
