import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { SesionUsuario } from 'app/modelos/sesionUsuario.model';
import { UsuarioService } from './usuario.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  private sesionActualSubject: BehaviorSubject<SesionUsuario>;
  public sesionActual: Observable<SesionUsuario>;
  
  private url = 'Account/Login';

  constructor(private http: HttpClient, private _usuarioService: UsuarioService) {
    this.sesionActualSubject = new BehaviorSubject<SesionUsuario>(this.getSesionDelLocalStorage());
    this.sesionActual = this.sesionActualSubject.asObservable();
  }

  autenticar(username: string, password: string, recordar: boolean): Observable<SesionUsuario> {
    const headers = new HttpHeaders ({
      'Content-type': 'application/json'
    });
    
    const options = {
      headers
    };

    return this.http.post<any>(`${environment.backend}${this.url}`, { NombreDeUsuario: username, Password: password }, options )
      .pipe(map(async response => {
        const sesion = new SesionUsuario(response.datos.token, new Date(response.datos.vencimiento));
        this.sesionActualSubject.next(sesion);
        
        if(recordar) {
          this.guardarSesionEnLocalStorage(sesion);
        }
        const usuario = await this._usuarioService.getUsuarioActualPromise();
        localStorage.setItem('ROLE', usuario.rol);

        return true;
      }), catchError(err => this.manejarError(err)));
  }

  private manejarError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401){
      return of(false);
    }
    return throwError(`Status Code: ${error.status}. Error: ${error.message}`);
  }

  public desloguear(): void {
    this.borrarSesionDelLocalStorage();
    this.borrarRoleUsuario();
    this.sesionActualSubject.next(null);
  }

  public get getSesionActual(): SesionUsuario {    
    return this.sesionActualSubject.value;
  }

  public sesionEsValida(): boolean {
    const sesionActual: SesionUsuario = this.getSesionActual;
    return sesionActual !== null && sesionActual !== undefined && this.tokenIsValid(sesionActual);
  }

  public tokenIsValid(sesionActual : any): boolean
  {
    var dateSesion = new Date(sesionActual.fechaDeExpiracion).getTime();
    return dateSesion > Date.now()
  }

  private guardarSesionEnLocalStorage(usuario: SesionUsuario): void {
    localStorage.setItem('sesionUsuario', JSON.stringify(usuario));
  }

  private borrarSesionDelLocalStorage(): void {
    localStorage.removeItem('sesionUsuario');
  }

  private borrarRoleUsuario(): void {
    localStorage.removeItem('ROLE');
  }

  private getSesionDelLocalStorage(): SesionUsuario {
    return JSON.parse(localStorage.getItem('sesionUsuario'));
  }

  public getRole() {
    return localStorage.getItem('ROLE');
  }
  
}
