import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';
import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';
import { MatAutocompleteModule, MatFormFieldModule } from '@angular/material';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { LoginComponent } from './main/pages/authentication/login/login.component';
import { LoginModule } from './main/pages/authentication/login/login.module';
import { Error404Component } from './main/pages/errors/404/error-404.component';
import { Error404Module } from './main/pages/errors/404/error-404.module';
import { AuthGuard } from './helpers/auth.guard';

const appRoutes: Routes = [
    {
        path: 'apps',
        loadChildren: './main/apps/apps.module#AppsModule',
        canActivate: [AuthGuard],
    },
    {
        path: 'pages',
        loadChildren: './main/pages/pages.module#PagesModule',
    },
    {
        path: 'ui',
        loadChildren: './main/ui/ui.module#UIModule',
    },
    {
        path: 'documentation',
        loadChildren:
            './main/documentation/documentation.module#DocumentationModule',
    },
    {
        path: 'angular-material-elements',
        loadChildren:
            './main/angular-material-elements/angular-material-elements.module#AngularMaterialElementsModule',
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: '',
        redirectTo: 'apps/bandeja',
        pathMatch: 'full',
    },
    {
        path: '**',
        component: Error404Component,
    },
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true,
        }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        AppStoreModule,

        // MatAutoComplete
        MatAutocompleteModule,
        MatFormFieldModule,

        //Login
        LoginModule,

        //Error
        Error404Module,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: MAT_DATE_LOCALE, useValue: "es-AR" },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
