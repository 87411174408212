import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../../servicios/auth.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';
import { AmbienteService } from 'app/servicios/ambiente.service';

@Component({
    selector     : 'login',
    templateUrl  : './login.component.html',
    styleUrls    : ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class LoginComponent implements OnInit
{
    loginForm: FormGroup;
    buttonIniciarEnabled: boolean;
    validLogin: boolean;
    version: string;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _authService: AuthService,
        private _router: Router,
        private _ambienteService: AmbienteService        
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        this.validLogin = true;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    async ngOnInit(): Promise<void>
    {
        this.buttonIniciarEnabled = true;
        this.loginForm = this._formBuilder.group({
            email   : ['', [Validators.required]],
            password: ['', Validators.required],
            recordar: [true]
        });
        this.version = await this._ambienteService.getVersion();
    }

    ingresar(): void{
        this.buttonIniciarEnabled = false;
        this._authService.autenticar(this.loginForm.value.email, this.loginForm.value.password, this.loginForm.value.recordar)
        .subscribe(response => {
            if (response){
                this.validLogin = true;
                this._router.navigateByUrl('/apps/bandeja');
            }else{                
                this.buttonIniciarEnabled = true;
                this.validLogin = false;
            }
        });
    }
}
