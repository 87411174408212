export const environment = {
    production: false,
    hmr: false,
    backend: "https://isac-backend-desa.softingba.com.ar/api/",
};

// // backend donweb
// export const environment = {
//     production: true,
//     hmr: false,
//     backend: "https://isac-backend-desa.softingba.com.ar/api/",
// };
