import { InMemoryDbService } from 'angular-in-memory-web-api';

import { ProjectDashboardDb } from 'app/fake-db/dashboard-project';
import { AnalyticsDashboardDb } from 'app/fake-db/dashboard-analytics';
import { CalendarFakeDb } from 'app/fake-db/calendar';
import { ECommerceFakeDb } from 'app/fake-db/e-commerce';
import { AcademyFakeDb } from 'app/fake-db/academy';
import { MailFakeDb } from 'app/fake-db/mail';
import { ChatFakeDb } from 'app/fake-db/chat';
import { FileManagerFakeDb } from 'app/fake-db/file-manager';
import { ContactsFakeDb } from 'app/fake-db/contacts';
import { TodoFakeDb } from 'app/fake-db/todo';
import { ScrumboardFakeDb } from 'app/fake-db/scrumboard';
import { InvoiceFakeDb } from 'app/fake-db/invoice';
import { ProfileFakeDb } from 'app/fake-db/profile';
import { SearchFakeDb } from 'app/fake-db/search';
import { FaqFakeDb } from 'app/fake-db/faq';
import { KnowledgeBaseFakeDb } from 'app/fake-db/knowledge-base';
import { IconsFakeDb } from 'app/fake-db/icons';
import { ChatPanelFakeDb } from 'app/fake-db/chat-panel';
import { QuickPanelFakeDb } from 'app/fake-db/quick-panel';
import { GruposFakeDb} from 'app/fake-db/grupos';
import { ChecklistsFakeDb } from './checklists';
import { UsuariosFakeDb } from './usuarios';
import { ResultadosFakeDb} from './resultados';
import { AreasFakeDb } from './areas';
import { LogincFakeDb } from './login';
import { MotivosFakeDb } from './motivos';
import { TicketFakeDb } from './ticket';
import { ReglasFakeDb } from './reglas';
import { RankinInspectoresFakeDb } from './RankingInspectores';
import { ProcesosFakeDb } from './procesos';
import { TiempoPromedioFakeDb } from './TiempoActividad';

export class FakeDbService implements InMemoryDbService
{
    createDb(): any
    {
        return {
            // Dashboards
            'project-dashboard-projects' : ProjectDashboardDb.projects,
            'project-dashboard-widgets'  : ProjectDashboardDb.widgets,
            'analytics-dashboard-widgets': AnalyticsDashboardDb.widgets,

            // Calendar
            'calendar': CalendarFakeDb.data,

            // E-Commerce
            'e-commerce-products' : ECommerceFakeDb.products,
            'e-commerce-orders'   : ECommerceFakeDb.orders,

            // Academy
            'academy-categories': AcademyFakeDb.categories,
            'academy-courses'   : AcademyFakeDb.courses,
            'academy-course'    : AcademyFakeDb.course,

            // Mail
            'mail-mails'  : MailFakeDb.mails,
            'mail-folders': MailFakeDb.folders,
            'mail-filters': MailFakeDb.filters,
            'mail-labels' : MailFakeDb.labels,

            // Chat
            'chat-contacts': ChatFakeDb.contacts,
            'chat-chats'   : ChatFakeDb.chats,
            'chat-user'    : ChatFakeDb.user,

            // File Manager
            'file-manager': FileManagerFakeDb.files,

            // Contacts
            'contacts-contacts': ContactsFakeDb.contacts,
            'contacts-user'    : ContactsFakeDb.user,

            // Todo
            'todo-todos'  : TodoFakeDb.todos,
            'todo-filters': TodoFakeDb.filters,
            'todo-tags'   : TodoFakeDb.tags,

            // Scrumboard
            'scrumboard-boards': ScrumboardFakeDb.boards,

            // Invoice
            'invoice': InvoiceFakeDb.invoice,

            // Profile
            'profile-timeline'     : ProfileFakeDb.timeline,
            'profile-photos-videos': ProfileFakeDb.photosVideos,
            'profile-about'        : ProfileFakeDb.about,

            // Search
            'search': SearchFakeDb.search,

            // FAQ
            'faq': FaqFakeDb.data,

            // Knowledge base
            'knowledge-base': KnowledgeBaseFakeDb.data,

            // Icons
            'icons': IconsFakeDb.icons,

            // Chat Panel
            'chat-panel-contacts' : ChatPanelFakeDb.contacts,
            'chat-panel-chats': ChatPanelFakeDb.chats,
            'chat-panel-user': ChatPanelFakeDb.user,

            // Quick Panel
            'quick-panel-notes' : QuickPanelFakeDb.notes,
            'quick-panel-events': QuickPanelFakeDb.events,

            // Grupos
            'grupos' : GruposFakeDb.grupos,
            
            // checklists
            'checklists' : ChecklistsFakeDb.checklists,

            // Usuarios
            'usuarios' : UsuariosFakeDb.usuarios,

            // Resultados
            'resultados' : ResultadosFakeDb.resultados,

            // Areas
            'areas' : AreasFakeDb.areas,

            // login
            'login': LogincFakeDb.data,

            // Motivos
            'motivos': MotivosFakeDb.motivos,

            // Tickets
            
            // 'tickets': TicketFakeDb.tickets,
            
            // Reglas
            'reglas': ReglasFakeDb.reglas,

            'ranking': RankinInspectoresFakeDb.usuarios,
            'proceso': ProcesosFakeDb.procesos,
            'tiempo-por-actividad': TiempoPromedioFakeDb.TiempoPorActividad

        };
    }
}
