import { Grupo } from 'app/modelos/grupo.model';
import { TipoDocumento } from './tipos/TipoDocumento';
import { TipoRol } from './tipos/TipoRol';



export class Usuario
{
    id: number;
    nombre: string;
    apellido: string;
    imagenPerfil: string;
    telefono: string;
    email: string;
    documento: string;
    grupos: Array<Grupo>;
    rol: string;
    tipoDocumento: string;
    mostrarMas?: false;
    paginationLimit?: number;
    usuarioCompleto?: string;
    nombreDeUsuario: string;
    generarClaveAleatoria: boolean;
    /**
     * To-Do
     * ImagenPerfil: Archivo
     * TipoDocumento: TipoDocumento
     * Roles : ??
     */
    
    /**
     * Constructor
     *
     * @param usuario
     */

    constructor(usuario?)
    {
        {
            usuario = usuario || {};
            this.id = usuario.id;
            this.nombre = usuario.nombre || '';
            this.apellido = usuario.apellido || '';
            this.imagenPerfil = usuario.imagenPerfil || 'assets/images/avatars/profile.jpg';
            this.telefono = usuario.telefono || '';
            this.email = usuario.email || '';
            this.tipoDocumento = usuario.tipoDocumento || '';
            this.documento = usuario.documento || '';
            this.grupos = usuario.grupos || '';
            this.usuarioCompleto = usuario.usuarioCompleto || '';
            this.nombreDeUsuario = usuario.nombreDeUsuario || '';
            this.generarClaveAleatoria = usuario.generarClaveAleatoria;
            this.rol = usuario.rol || '';
        }
    }

}


