export class AreasFakeDb {
    public static areas = [
        {
            id: '1',
            nombre: 'Obras',
            descripcion: 'Descripcion 1. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.',
            grupos: ['Analistas', 'Coordinadores', 'Inspectores']      
        },

        {
            id: '2',
            nombre: 'Higiene',
            descripcion: 'Descripcion 2. At vero eos et accusamus et iusto.',
            grupos: ['Inspectores', 'Analistas', 'Coordinadores']   
        },

        {
            id: '3',
            nombre: 'Seguridad',
            descripcion: 'Descripcion 3. "Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            grupos: ['Coordinadores', 'Inspectores', 'Analistas']
        },

        {
            id: '4',
            nombre: 'Logistica',
            descripcion: 'Descripcion 4. Quis autem vel eum.',
            grupos: ['Directores']
        },

        {
            id: '5',
            nombre: 'Sanidad',
            descripcion: 'Descripcion 5. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit.',
            grupos: ['Inspectores', 'Analistas', 'Coordinadores', 'Inspectores', 'Analistas', 'Coordinadores', 'Inspectores', 'Analistas', 'Coordinadores']
        },

        {
            id: '6',
            nombre: 'Habilitaciones',
            descripcion: 'Descripcion 6',
            grupos: ['Coordinadores', 'Directores', 'Analistas']
        }
    ];

}
