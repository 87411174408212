import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})

export class AmbienteService {
    private url = 'Ambiente';

    constructor(
        private _httpClient: HttpClient,
    ) {}

    /**
     * Get Versión
     * Devuelve versión actual
     * @returns {Promise<string>}
     */
    getVersion(): Promise<string> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(`${environment.backend}${this.url}/Version`)
                .subscribe((response: string) => {
                    resolve(response);
                }, reject);
        })
    }
}
